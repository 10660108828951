.shopping__cart-heading {
    margin-top: 4.5rem;
    margin-bottom: 2rem;
}

.shopping__cart-body {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    grid-column-gap: 20px;
}

.shopping__cart-card {
    margin-bottom: 1rem;
}

.shopping__cart-card__body {
    min-height: 100px;
    background-color: var(--container-color);
    border-radius: .5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    position: relative;
}

.sc__card-title {
    display: block;
    font-weight: 500;
}

.sc__card-img {
    width: 9.375rem;
    height: 9.375rem;
}

.sc__card-price {
    font-weight: 600;
    color: var(--first-color);
}

.sc__card-subprice {
    font-size: var(--smaller-font-size);
}

.sc__card-spinner {
    margin-left: auto;
}

.shopping__cart-price__card {
    padding: 1rem;
    min-height: 300px;
    border-radius: .5rem;
    background-color: var(--container-color);
}

.sc__pc-title {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 1rem;
}

.sc__pc-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 3;
    font-size: var(--small-font-size);
}

.sc__pc-item.grand {
    font-weight: 600;
    color: var(--first-color);
    margin-bottom: 2rem;
}

.sc__pc-ctp {
    width: 100%;
    font-weight: 600;
}

.shopping__cart-img__container {
    position: relative;
    padding: .75rem;
}

.sc__item__remove {
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    color: lightgray;
}

.sc__item__remove:hover {
    color: var(--second-color);
}

.shopping__cart-empty {
    background-color: var(--container-color);
    border-radius: .5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.shooping__cart-value {
    display: flex;
    align-items: center;
    font-size: var(--smaller-font-size);
    padding: .75rem;
    border: 1px dashed #3bb896;
    background-color: #f2fff8;
    /* color: #3bb896; */
    font-weight: 500;
    border-radius: 3px;
    margin: 1rem 0;
}

.shopping__cart-coupon__container {
    background-color: var(--container-color);
    padding: 1rem;
    border-radius: .5rem;
}

.sc__coupon-subtitle {
    font-size: var(--small-font-size);
    margin-bottom: 1rem;
}

.sc__form-container {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 60% 30%;
    align-items: center;

}

.sc__form-container input {
    font-size: var(--smaller-font-size);
}

.sc__coupon-subtitle span {
    font-weight: 600;
}

@media screen and (max-width: 850px) {
    .shopping__cart-body {
        display: block;
    }
}

@media screen and (max-width: 750px) {
    .shopping__cart-container {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media screen and (max-width: 465px) {
    .shopping__cart-card__body {
        display: block;
    }

    .sc__card-spinner {
        margin: 0;
        position: absolute;
        top: 25%;
        right: 15px;
        transform: translateY(25%);
    }
}

@media screen and (max-width: 370px) {
    .shopping__cart-heading {
        font-size: var(--h2-font-size);
        margin-bottom: 1rem;
    }

    .spinner__group {
        width: 80px;
        padding: .5rem;
    }
}
.not__found-container {
    background-color: #fff;
    padding: 3.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.title {
    font-size: 6.25rem;
    font-weight: 700;
}

.subtitle {
    text-transform: uppercase;
}

.description {
    line-height: 1.5;
    margin: 1rem 0;
}

@media screen and (max-width: 400px) {
    .not__found-container {
        padding: 1.5rem;
    }

    .title {
        font-size: 4.25rem;
    }
    
    .subtitle {
        font-size: 0.9rem;
    }

    .description {
        font-size: 0.8rem;
    }
}
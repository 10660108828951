.shipping__address-container {
    display: grid;
    grid-template-columns: 1fr .5fr;
    grid-column-gap: 20px;
    margin-top: 5.5rem;
}

.shipping__form-container,
.order__summary-container {
    background-color: var(--container-color);
    border-radius: 5px;
    padding: 1.5rem;
    border-radius: 10px;
}

.os__title {
    border-bottom: 1px solid #e2e2e2;
    margin-bottom: 0.7em;
    padding-bottom: 10px;
}

.os__price-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    font-size: var(--small-font-size);
}

.os__price-item.grand {
    font-weight: 600;
}

.os__product {
    display: grid;
    grid-template-columns: 60px 5fr .5fr;
    grid-column-gap: 10px;
    font-size: var(--smaller-font-size);
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
}

.order__summary-container .ant-collapse-header,
.order__summary-container .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
}


.os__grid-2,
.os__grid-3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
}

.os__grid-3 {
    grid-template-columns: repeat(3, 1fr);
}


/* Review & Payment */
.rp__address__note-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
}
.rp__anote-textarea {
    outline: none;
    resize: none;
    width: 100%;
    min-height: 150px;
    border-radius: 3px;
    border: 1px solid #e2e2e2;
    padding: .75rem;
    font-size: var(--small-font-size);
}

.rp__address-container {
    min-height: 100px;
    border-radius: 3px;
    background-color: rgba(212, 228, 250, 0.3);
    border: 1px solid #1d6ddf;
    color: var(--first-color);
    font-size: var(--small-font-size);
    padding: .75rem;
    margin-bottom: 2.5rem;
}

.rp__address-title {
    font-size: var(--normal-font-size);
    font-weight: 500;
    text-decoration: underline;
}

.rp__address-container span {
    display: block;
}

.rp__sa_edit {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
    margin-top: 1rem;
    color: #1d6ddf;
    display: flex;
    align-items: center;
}

.rp__payment_option .ant-radio-group {
    display: flex;
    flex-direction: column;
    width: fit-content;
}
.rp__payment_option .ant-radio-group label {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    border-color: transparent;
    box-shadow: none !important;
}

.rp__payment_option .ant-radio-group label:hover {
    color: #1d6ddf;
}

.rp__payment_option .ant-radio-group label .span__radio {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    border: 1px solid #e2e2e2;
    display: inline-block;
    margin-right: 5px;
}

.rp__payment_option .ant-radio-group label.ant-radio-button-wrapper-checked {
    color: #1d6ddf;
    /* border-color: #1d6ddf; */
    border-color: transparent;
    border-left: 2px solid #1d6ddf;
    border-right: transparent;
    font-weight: 600;
}

.rp__payment_option .ant-radio-group label.ant-radio-button-wrapper-checked .span__radio {
    background-color: #1d6ddf;
    border-color: transparent;
}

.cod__note {
    width: fit-content;
    margin-left: 35px;
    font-size: var(--small-font-size);
    padding: 0 .75rem;
    color: darkgrey;
    /* background-color: #f2fff8;
    border: 1px solid #3bb896;
    border-radius: 3px; */
}

.rp__online-msg {
    display: flex;
    align-items: center;
    padding: 0 .75rem;
    margin-left: 35px;
    margin-bottom: 1rem;
    font-size: var(--small-font-size);
    /* background-color: #f2fff8;
    border: 1px solid #3bb896;
    border-radius: 3px; */
}

@media screen and (max-width: 900px) {
    .shipping__address-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
}

@media screen and (max-width: 750px) {
    .shipping__address-container {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .rp__address__note-container { 
        grid-template-columns: 1fr;
    }

    .cod__note,
    .onlineBtn {
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 570px) {
    .os__grid-2,
    .os__grid-3 {
        grid-template-columns: 1fr;
    }
}
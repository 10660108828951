/* ========== Login Screen ========== */
.login__section-container {
    background-color: var(--container-color);
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 2rem 0;
    border-radius: .75rem;
}

.login__container,
.signup__container {
    padding: 2.5rem 0;
    width: 400px;
    margin: auto;
}

.login__container .section__small-title,
.signup__container .section__small-title {
    margin-top: -10px;
}

.signin__with-google,
.signup__with-google {
    padding: 0.75rem;
    border: 1px solid #e0e0e0;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    cursor: pointer;
    user-select: none;
}

.signin__with-google span,
.signup__with-google span {
    font-weight: 500;
    color: var(--first-color);
    margin-left: 10px;
}

.signin__with-email,
.signup__with-email {
    background-color: #e0e0e0;
    height: 1px;
    margin: 2rem 0;
    position: relative;
}

.signin__with-email::before,
.signup__with-email::before {
    content: 'or Sign in with Email';
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
    color: #bebebe;
    font-size: var(--smaller-font-size);
    background-color: var(--container-color);
    padding: 10px;
}

.signup__with-email::before {
    content: 'or Sign up with Email';
}

.forgot__password {
    font-size: var(--small-font-size);
    color: var(--first-color);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0;
    font-weight: 500;
}

.personal__data {
    font-size: var(--smaller-font-size);
    color: var(--first-color);
    margin: 20px 0;
    font-weight: 400;
}

.personal__data span {
    color: var(--second-color);
    text-decoration: underline;
}

.not__registered-yet {
    /* margin-top: 1.5rem; */
    font-weight: 500;
    font-size: var(--smaller-font-size);
}

.not__registered-yet span {
    color: var(--second-color);
    cursor: pointer;
}

.testimonials__container {
    background-color: var(--first-color);
    background: url("https://images.unsplash.com/photo-1633613286991-611fe299c4be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
    border-radius: 0 .75rem .75rem 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

}

.testimonials__content {
   background: rgba(255,255,255,0.5);
   border-radius: 10px;
   border: 1px solid rgba(255,255,255,0.2);
   position: absolute;
   backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
   display: block;
   width: 75%;
   height: 450px;
   margin: 0 auto;
}

.testimonials__content .swiper-button-next, 
.testimonials__content .swiper-button-prev {
    top: initial;
    bottom: 10%;
    width: initial;
    height: initial;
    background-color: transparent;
    border: 2px solid var(--text-color);
    padding: 5px;
    border-radius: 100%;
    font-size: 1.5rem;
    color: var(--first-color);
    /* z-index: var(--z-tooltip); */
    z-index: 99999;
}

.testimonials__content .swiper-button-next::after,
.testimonials__content .swiper-button-prev::after {
    content: '';
}

.testimonials__content .swiper-button-prev {
    left: calc(100% - 6rem);
}

.tms__quote {
    padding: 1rem;
}

.tms__quote-content {
    font-size: 1rem;
    line-height: 2;
    margin-top: 5rem;

}

.tms__quote-name {
    font-size: 1.2rem;
    font-weight: 600;
} 

.tms__quote-stars {
    width: 100px;
    display: flex;
    justify-content: space-between;
}

.testimonials__content .tms__quote-footer {
    position: absolute;
    left: 0;
    bottom: 25%;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 500;
}


@media screen and (max-width: 1200px) {
    .tms__quote-content {
        margin: 0;
    }
}

@media screen and (max-width: 950px) {
    .testimonials__content {
        width: 85%;
    }

    .login__container, .signup__container {
        width: 300px;
    }
}

@media screen and (max-width: 950px) {
    .tms__quote-content,
    .tms__quote-name,
    .tms__quote-stars {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 700px) {
    .login__section-container {
        grid-template-columns: 1fr;
    }

    .testimonials__container {
        display: none;
    }

    .login__container, .signup__container {
        max-width: 500px;
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

@media screen and (max-width: 385px) {
    .signin__with-email::before,
    .signup__with-email::before {
        font-size: 0.628rem;
    }
}
.search-bar-section {
    align-items: center;
    background-color: #1488cc;
    background: linear-gradient(90deg,#2b32b2,#1488cc);
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5rem;
    margin-top: 1rem;
    min-height: 150px;
    padding: 1rem;
}

.search-bar-section .title {
    color: var(--container-color);
    font-size: 2rem;
    font-weight: 300;
}

.search-bar-section .input-group {
    position: relative;
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.search-bar-section .input-group .input {
    background-color: var(--container-color);
    border: none;
    border-radius: 3px;
    box-shadow: 0 12px 16px hsla(210, 60%, 45%, .1);
    color: var(--first-color);
    margin: auto auto -85px;
    outline: none;
    padding: 1.2rem 1.2rem 1.2rem 60px;
    width: 80%;
    font-size: 1rem;
}

.search-bar-section .input-group .icon {
    color: hsl(0, 0%, 85%);
    font-size: 1.5rem;
    left: 12%;
    position: absolute;
    top: 42px;
}

@media screen and (max-width: 576px) {
    .search-bar-section { 
        min-height: 100px;
    }
    .search-bar-section .title { 
        font-size: 1.2rem;
    }

    .search-bar-section .input-group .input {
        width: 95%;
        font-size: var(--small-font-size);
        padding: .75rem .75rem .75rem 35px;
        margin-bottom: -55px;
        font-size: .75rem;
    }

    .search-bar-section .input-group .icon { 
        top: 25px;
        left: 5%;
        font-size: 1.2rem;
    }
}

.all-brands-catalogue {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
}

.all-brands-catalogue .fb__card {
    width: auto;
    height: auto;
    margin: 0;
    border-radius: 10px;
    border: 2px solid transparent;
}

.all-brands-catalogue .fb__card:hover {
    border: 2px solid var(--first-color);
}

.all-brands-catalogue .fb__card img {
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 900px) {
    .all-brands-catalogue {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (max-width: 750px) {
    .all-brands-catalogue {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media screen and (max-width: 500px) {
    .all-brands-catalogue {
        grid-template-columns: repeat(3, 1fr);
    }
}


/* Product Grid Section  */
.custom__breadcrumb-container {
    margin-top: 5rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 750px) {
    .custom__breadcrumb-container {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.product__grid-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 10px;
}

.product__grid-section .product__card {
    width: auto;
    height: auto;
    border-radius: 10px;
}

.product__grid-section .product__card-img {
    height: 250px;
}

.product__grid-section .product__card img {
    min-width: 90px;
    min-height: inherit;
    max-width: 90%;
    max-height: 200px;
    padding: 0;
}

.product__grid-section .product__title {
    font-size: var(--normal-font-size);
}

@media screen and (max-width: 900px) {
    .product__grid-section {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 750px) {
    .product__grid-section {
        grid-column-gap: 0;
        grid-row-gap: 0;
    }

    .product__grid-section .product__card {
        border-radius: 0;
        border-right: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
    }
}

@media screen and (max-width: 600px) {
    .product__grid-section {
        grid-template-columns: repeat(2, 50%);
    }
}

@media screen and (max-width: 500px) {
    .product__grid-section .product__title {
        font-size: var(--smaller-font-size);
    }

    .product__grid-section .product__card-img {
        height: 210px;
    }

    .search-bar-section {
        margin: 3rem;
    }
    
}
.spin__loading {
    background-color: var(--container-color);
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
}

.spin__loading i {
    background-color: var(--text-color);
}

.myaccount__breadcrumb-container {
    padding: 1rem 0;
}

.dashboard__section {
    background-color: var(--container-color);
    padding: 2rem;
    border-radius: 5px;
    font-size: var(--small-font-size);
    max-width: 800px;
}

.ac__heading .name,
.ac__heading a,
.ac__subtitle .link {
    color: var(--second-color);
    font-weight: 500;
}

.ac__section-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    margin-top: 2rem;
}

.box-item-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    color: var(--text-color);
    margin-bottom: 1.5rem;
    padding: 1rem;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
}

.box-item-group svg {
    font-size: 2.5rem;
}

.box-item-group a {
    color: var(--text-color);
}

.box-item-group:hover {
    background-color: var(--first-color);
    border-color: var(--first-color);
    color: var(--container-color);
}

.box-item-group:hover a {
    color: var(--container-color);
}

@media screen and (max-width: 750px) {
    .myaccount {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}

@media screen and (max-width: 580px) {
    .ac__section-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 375px) {
    .ac__section-grid {
        grid-template-columns: 1fr;
    }
}


/* Orders */
.ac__section-title {
    display: flex;
    align-items: center;
    font-size: var(--h3-font-size);
    font-weight: 500;
    color: var(--first-color);
}

.order-item-group { 
    margin-bottom: 1.5rem;
}

.order-item-group .heading, 
.order-item-group .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
}

.order-item-group .heading {
    background-color: #ebe9e9;
    color: var(--first-color);
}

.order-item-group .heading .left-content .id {
    font-size: 1.05rem;
    font-weight: 600;
}

.order-item-group .heading .left-content .time {
    font-size: 0.72rem;
    font-weight: 500;
}

.order-item-group .heading .right-content {
    display: flex;
    align-items: center;
}

.order-item-group .heading .right-content .invoice a { 
    color: var(--first-color);
    margin-right: 1rem;
    font-weight: 600;
}

.order-item-group .heading .right-content .view-btn {
    outline: none;
    background-color: transparent;
    border: 2px solid var(--first-color);
    color: var(--first-color);
    padding: 0.1rem 0.8rem;
    text-align: center;
    font-weight: 500;
    border-radius: 2px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.order-item-group .body {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

}

.order-item-group .body  .title {
    text-align: center;
    color: #00bc22;
    font-weight: 600;
    font-size: var(--normal-font-size);
}

.order-item-group .body .paid span, 
.order-item-group .body .saved span {
    display: block;
    text-align: center;
    font-weight: 500;
}

.order-item-group .body .saved span {
    font-weight: 700;
}

/* Account Details */
.ac__password-grid {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    grid-column-gap: 20px;
}

.ac__password-container {
    border: 1px solid #dfdfdf;
    padding: 1rem;
}

.pass__title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--first-color);
}

.ac__form-group {
    margin-bottom: 1rem;
    position: relative;
}

.ac__form-group input {
    border: 1px solid #d9d9d9;
    display: block;
    outline: none;
    padding: 0.5rem;
    width: 100%;
}

.ac__form-group label {
    font-size: var(--smaller-font-size);
}

.reset-btn {
    background-color: var(--first-color);
    border: none;
    color: #fff;
    font-size: .8rem;
    font-weight: 500;
    margin-top: 1rem;
    outline: none;
    padding: 0.8rem 3rem;
    text-transform: uppercase;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .ac__password-grid {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
    }
}
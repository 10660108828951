.product__details-container {
    width: 100%;
    display: grid;
    grid-template-columns: 35% 70%;
    padding: 0.75rem;
    padding-bottom: 2rem;
    position: relative;
    background-color: var(--container-color);

}

/******************* ZOOM Screen *******************/
.myGallery2 {
    /* max-height: 350px; */
    height: 80%;
    width: 100%;
    background-color: var(--container-color);
    border: 1px solid #f0f0f0;
}

.myGallery2 .swiper-wrapper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.myGallery2 .swiper-wrapper .swiper-slide.swiper-slide-active {
    padding: 1rem;
}

.myGallery {
    height: 20%;
    margin-top: 10px;
}

.myGallery .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #f0f0f0;
    padding: 5px;
    margin-bottom: 5px;
    width: 60px;
    height: 60px;
}

.myGallery .swiper-slide img {
    opacity: 0.4;
    width: 50px;
    height: auto;
}

.myGallery .swiper-slide-thumb-active {
    border: 2px solid var(--first-color);
}

.myGallery .swiper-slide-thumb-active img {
    opacity: 1;
}

#portal {
    position: absolute;
    top: 0;
    left: 100%;
    right: 0;
    width: 180%;
    height: 100%;
    overflow: hidden;
    background-color: var(--container-color);
}

.product__details__gallery-content {
    position: sticky;
    top: 71px;
    z-index: 1;
}

.product__details-content {
    padding: 0 1rem;
    max-width: 800px;
}

.product__breadcrumb-container {
    font-size: var(--smaller-font-size);
    margin: .5rem 0;
    height: 20px;

    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    max-width: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product__breadcrumb-container:hover {
    height: auto;
}

.product__details-title {
    line-height: 1.2;
    margin-bottom: 10px;
}

.product__details-shortDesc {
    font-size: .8rem;
    line-height: 1.5;
}

.product__details-shortDesc h2 {
    font-size: .8rem;
    line-height: 1.5;
    color: var(--text-color)
}

.product__details-shortDesc ul {
    list-style: inside;
}

.product__details-functional__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    margin: 1rem 0;
}

.product__details-manufacturer {
    border: 1px solid #f0f0f0;
    padding: 5px;
    margin: 5px 0;
    width: fit-content;
    font-size: var(--smaller-font-size);
    display: flex;
    align-items: center;
}

.product__details-manufacturer span {
    font-weight: 600;
    margin-right: 5px;
}

.product__dispatch {
    width: fit-content;
    padding: .75rem 0;
    font-size: var(--smaller-font-size);
    font-weight: 500;
    display: flex;
}

.product__dispatch .content {
    display: flex;
    align-items: center;
}

.quantity__container {
    display: flex;
    align-items: center;
    width: fit-content;
}

.quantity__container span {
    font-size: var(--smaller-font-size);
    font-weight: 600;
}

.quantity__container input {
    outline: none;
    border: 1px solid #d9d9d9;
    width: 60px;
    height: 40px;
    margin-left: 1rem;
    text-align: center;
    border-radius: 3px;
}

.qty__action-btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: space-between;
    /* max-width: 80px; */
    margin-left: 5px;
    grid-column-gap: 5px;
}

.product__details-price__section {
    margin: 5px 0;
    width: fit-content;
}

.product__details-price__section .sale__price,
.var__container .sale__price {
    font-size: var(--h2-font-size);
    font-weight: 600;
    color: var(--first-color);
}

.product__details-price__section .mrp__price,
.var__container .mrp__price {
    text-decoration: line-through;
    font-weight: 500;
    margin: 0 5px;
}

.product__details-price__section .off,
.var__container .off {
    font-size: var(--small-font-size);
    font-weight: 600;
    color: var(--second-color);
}

.product__details-price__section .you__save,
.var__container .you__save {
    font-size: var(--smaller-font-size);
    font-weight: 600;
    width: fit-content;
}


.var__container {
    border: 1px solid #f0f0f0;
    padding: 5px;
    padding-right: 15px;
    margin-bottom: 1rem;
    max-width: 800px;
    display: flex;
    align-items: center;
}

.var__title {
    font-size: 0.9rem;
    font-weight: 600;
}

.var__pqty {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.var__pqty .qty__action-btns span {
    display: none;
}

/* Similar/Related Products */
.product__srow-container {
    position: relative;
    margin-bottom: 2rem;
}

.product__srow-container .swiper {
    position: initial;
}

.product__srow-container .swiper-button-next::after,
.product__srow-container .swiper-button-prev::after {
    content: '';
}

.product__srow-container .swiper-button-next,
.product__srow-container .swiper-button-prev {
    top: initial;
    bottom: 92%;
    width: initial;
    height: initial;
    background-color: transparent;
    border: 2px solid var(--text-color);
    padding: 6px;
    border-radius: .5rem;
    font-size: 1.5rem;
    color: var(--first-color);
    z-index: var(--z-tooltip);
}

.product__srow-container .swiper-button-prev {
    left: calc(100% - 6rem);
}

/* Accordion Section */
.product__tabs-section {
    max-width: 800px;
    overflow: hidden;
}

.accordion__container {
    border: 0px;
}

.accordion__item.ant-collapse-item-active {
    /* background-color: hsl(228, 100%, 97%); */
    box-shadow: 0 12px 32px hsla(228, 66%, 45%, .1);
    background-color: #f0f4fd;
}

.accordion__item {
    margin-bottom: .5rem;
    border: 2px solid var(--body-color) !important;
    border-radius: .5rem;
    padding: .5rem;
    transition: .4s;
    /* border: 0 ; */
}

.accordion__item .ant-collapse-header {
    font-weight: 500;
}



.accordion__item .ant-collapse-content {
    font-size: var(--small-font-size);
    padding: 1.25rem 2.5rem 0 1.5rem;
    line-height: 2;
    background-color: transparent;
    transition: all .5s ease;
}

.accordion__item .ant-collapse-content ul {
    list-style: inside;
}




/* Whatsapp Status Online */
.wa__btn-container {
    width: fit-content;
    margin: 1rem 0;
}

.wa__status-online {
    backface-visibility: hidden;
    background: #2db742;
    cursor: pointer;
    transition: all .4s ease !important;
    will-change: transform;

    border-radius: 50vh;

    box-shadow: 0 4px 8px 1px rgba(32, 32, 37, .09) !important;
    color: #fff;
    display: block;
    line-height: 1;
    min-height: 64px;
    position: relative;
    text-decoration: none !important;
    max-width: 300px;

    box-sizing: border-box;
}

.wa__status-online:hover {
    box-shadow: 0 4px 8px 1px rgb(32 32 37 / 19%);
    transform: translateY(-3px);
    color: #fff;
}

.wa__status-online .wa__btn-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.wa__btn-txt {
    position: relative;
    padding: 16px 20px 15px 71px;
    display: inline-block;
    font-size: 12px;
    line-height: 1.33em;
}

.wa__cs-info {
    margin-bottom: 2px;
}

.wa__cs-name {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.36em;
}

.wa__cs-name,
.wa__cs-status {
    display: inline-block;
}

.wa__cs-status {
    background: #62c971;
    border-radius: 5px;
    color: rgba(255, 255, 255, .98);
    font-size: 9px;
    height: 14px;
    left: 0;
    line-height: 1.34em;
    margin-left: 3px;
    padding: 1px;
    position: relative;
    text-align: center;
    top: -1px;
    width: 36px;
}

.wa__btn-title {
    font-size: 14px;
    font-weight: 600;
    padding-left: 2px;
}

/* Whatsapp Status Online Live */

.whatsapp__btn__container {
    width: 250px;
    height: 60px;
    margin: 1rem 0;
    border-radius: 200px;
    background-color: #007456;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    box-shadow: 0 4px 8px 1px rgba(64, 140, 127, .2) !important;
}

.whatsapp__icon svg {
    width: 32px;
    height: 32px;
    margin-top: 6px;
}

.whatsapp__text {
    color: #fff;
    font-weight: 500;
    text-wrap: nowrap;
}

/* Product share container */
.product__share-container {
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
}

.share__product-link {
    margin-left: 10px;
    margin-top: 2px;
    display: grid;
    grid-template-columns: repeat(4, 20px);
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
    /* width: 300px; */
}

.share__product-link a {
    color: var(--text-color);
}


@media screen and (max-width: 1275px) {
    .product__details-shortDesc,
    .product__variation-section,
    .product__tabs-section {
        width: 680px;
    }
    
}

@media screen and (max-width: 1150px) {
    .product__details-shortDesc,
    .product__variation-section,
    .product__tabs-section {
        width: 580px;
    }
    
}

@media screen and (max-width: 900px) {
    .product__details-container {
        display: block;
    }

    .product__details-content {
        margin-top: 2.5rem;
    }

    .product__details-shortDesc,
    .product__variation-section,
    .product__tabs-section {
        width: 100% !important;
    }

    .myGallery2 .swiper-button-next:after,
    .myGallery2 .swiper-button-prev:after {
        font-size: 2rem;
    }

    #portal {
        display: none;
    }
    
}

@media screen and (max-width: 750px) {
    /* .product__srow-container, */
    .section {
        padding-left: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .var__container {
        display: block;
    }
}

@media screen and (max-width: 480px) {
    .var__pqty {
        display: block;
    }

    .quantity__container {
        margin-top: 1rem;
    }
    .quantity__container input {
        margin-left: 0;
        width: 40px;
    }

    .var__pqty .qty__action-btns span {
        display: block;
    }
}

@media screen and (max-width: 360px) {
    .var__pqty .qty__action-btns span {
        font-size: 8px;
    }
}

@media screen and (max-width: 550px) {
    .product__details-title {
        font-size: var(--h1-font-size);
    }
}

@media screen and (max-width: 440px) 
{
    .accordion__item .ant-collapse-content {
        padding-right: .5rem;
    }

    .product__details-title {
        font-size: var(--h2-font-size);
    }

    .qty__action-btns span {
        display: none;
    }

    .wa__status-online {
        max-width: 100%;
        min-height: 45px;
    }

    .wa__btn-icon svg {
        width: 30px;
        height: 30px;
    }
    
    .wa__btn-title {
        font-size: 11px;
    }

    .wa__btn-txt {
       padding: 5px 20px 0px 55px;
    }
    
    .wa__cs-name {
        font-size: 9.5px;
    }

    .wa__cs-status {
        font-size: 8px;
        height: 11px;
        width: 32px;
    }
    
}

@media screen and (max-width: 500px) 
{

}

.custom-class-name {
    display: grid;
    grid-template-columns: repeat(5, 60px);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    max-width: 450px;
    align-items: center;
    justify-content: center;
}

.d-none {
    display: none;
}
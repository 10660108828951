/* All Categories */
.all-categories-catalogue {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
}

.category__card-tag {
    background-color: #d4e4fa;
    color: #1d6ddf;
    border: 1px solid #1d6ddf;
    border-radius: 50px;
    padding: .25rem .75rem;
    text-align: center;
    font-size: var(--normal-font-size);
}

.category__card-tag:hover {
    background-color: #1d6ddf;
    color: #d4e4fa;
}


@media screen and (max-width: 400px) {
    .all-categories-catalogue {
        flex-direction: column;
        margin: 0 1rem;
    }

    .search-bar-section {
        margin-bottom: 3rem;
        margin-left: 5px;
        margin-right: 5px;
    }
}
.hero__img {
    border-radius: 0 0 10px 10px;
}

/* Featured Brands Section */
.fbrands__container {
    padding: 1rem 0 5rem;
}

.fb__card {
    width: 150px;
    height: 200px;
    background-color: var(--container-color);
    padding: .5rem .5rem 1.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    transition: .4s;
}

.fb__card img {
    border-radius: .5rem;
    width: 100px;
    height: 100px;
}

.fb__card:hover {
    box-shadow: 0 12px 16px hsla(210, 60%, 45%, .1);
}

@media screen and (max-width: 900px) {
    .fbrands__container {
        padding-bottom: 1rem;
    }

    .pgridSwiper .product__card {
        margin: 0 !important;
        border-radius: 0;
        border-right: 1px solid #ebebeb;
    }
    
    .product__container {
        padding-bottom: 0 !important;
    }
}

/* Swiper class */
.sideNavPrev {
    position: relative;
}

.sideNavPrev .swiper {
    position: initial;
}

.sideNavPrev .swiper-button-next::after,
.sideNavPrev .swiper-button-prev::after {
    content: '';
}

.sideNavPrev .swiper-button-next,
.sideNavPrev .swiper-button-prev {
    top: initial;
    bottom: 85%;
    width: initial;
    height: initial;
    background-color: transparent;
    border: 2px solid var(--text-color);
    padding: 6px;
    border-radius: .5rem;
    font-size: 1.5rem;
    color: var(--first-color);
    z-index: var(--z-tooltip);
}

.sideNavPrev .swiper-button-prev {
    left: calc(100% - 6rem);
}

@media screen and (max-width: 750px) {
    .section {
        margin-left: 1rem;
        /* margin-right: 1rem; */
    }
}

@media screen and (max-width: 1024px) {

    .sideNavPrev .swiper-button-next,
    .sideNavPrev .swiper-button-prev {
        bottom: 88%;
        padding: 0;
    }

    .sideNavPrev .swiper-button-prev {
        left: calc(100% - 4.5rem);
    }
}

/* Featured Categories Section */
.fcategories__container {
    display: grid;
    grid-template-columns: 76% 23%;
    grid-column-gap: 0.5rem;
    width: 100%;
    align-items: center;
}

.fcategories__container img {
    border-radius: .5rem;
}

.fc__card {
    width: 495px;
    height: 265px;
}

@media screen and (max-width: 1200px) {
    .fc__card {
        width: 415px;
        height: 255px;
    }
}
@media screen and (max-width: 900px) {
    .fcategories__container { 
        display: block;
    }
    .fc__card {
        width: 385px;
        height: 265px;
    }

    .app__download {
        display: none;
    }
}

/*==================== Product Card Section ====================*/
.product__container {
    padding: 1rem 0 2rem;
}

.product__card {
    width: 18.125rem;
    background-color: var(--container-color);
    padding: .5rem .5rem 1.5rem;
    border-radius: 10px;
    position: relative;
    transition: .4s;
}

.product__card-img {
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    padding: 5px;
}

.product__card-img img {
    min-width: 90px;
    min-height: inherit;
    max-width: 90%;
    max-height: 200px;
    padding: 0;
}

.product__data {
    padding: 0 1rem 0 .5rem;
}

.product__price {
    font-size: var(--h2-font-size);
    color: var(--first-color);
    margin-bottom: .25rem;
}

.product__price span.mrp {
    color: var(--second-color);
    font-size: var(--normal-font-size);
    text-decoration: line-through;
}

.product__price span.starting {
    color: var(--second-color);
    font-size: var(--normal-font-size);
}

.product__title {
    font-size: var(--h3-font-size);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: .75rem;
}

.product__description {
    font-size: var(--small-font-size);
}

.product__card:hover {
    box-shadow: 0 12px 16px hsla(210, 60%, 45%, .1);
}

.section__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.view__all-btn {
    display: inline-block;
    background-color: var(--first-color);
    color: var(--container-color);
    padding: 10px 25px;
    border-radius: .25rem;
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    box-shadow: 0 4px 8px hsla(210, 100%, 14%, .15);
    cursor: pointer;
    transition: .3s;
}

.view__all-btn svg {
    font-size: var(--normal-font-size);
    vertical-align: middle;
}

.view__all-btn:hover {
    box-shadow: 0 4px 12px hsla(210, 100%, 14%, .25);
    opacity: 0.8;
}

.pc__atc-btn {
    display: inline-block;
    background-color: var(--first-color);
    color: var(--container-color);
    padding: .5rem .75rem;
    border-radius: .5rem;
    font-size: 1.2rem;
    box-shadow: 0 4px 8px hsla(210, 100%, 14%, .1);
    cursor: pointer;

    position: absolute;
    bottom: 5%;
    right: 5%;
    transition: .3s;
}

@media screen and (max-width: 900px) { 
    .cb__section .section__title {
        margin: 0;
    }

    .view__all-btn {
        padding: 5px 10px;
    }
}

@media screen and (max-width: 750px) {
    .view__all-btn {
        margin-right: 12px;
    }

    .free__shipping,
    .super__sale {
        padding: 8px !important;
        border-radius: 3px !important;
    }
}

/*==================== Free Shipping Section ====================*/
.free__shipping, 
.super__sale {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .75rem;
}

.free__shipping {
    background-color: var(--container-color);
}

.super__sale {
    background-color: hsl(0, 0%, 89%);
}
/*==================== GOOGLE FONTS ====================*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/*==================== VARIABLES CSS ====================*/
:root {
    --header-height: 3.8125rem;

    /*========== Colors ==========*/
    /* --first-color: hsl(210, 100%, 14%); */
    --first-color: hsl(232, 44%, 17%);
    --first-color-alt: hsl(210, 93%, 7%);
    --first-color-light: hsl(210, 93%, 27%);
    --first-color-lighten: hsl(210, 100%, 97%);
    /* --second-color: hsl(10, 84%, 47%); */
    --second-color: hsl(352, 96%, 65%);
    --body-color: hsl(228, 14%, 93%);
    /* --title-color: hsl(210, 100%, 14%); */
    --title-color: hsl(232, 44%, 17%);
    --text-color: hsl(215, 19%, 35%);
    --container-color: hsl(0, 0%, 100%);
    --white: hsl(0, 0%, 100%);

    /*========== Font and Typography ==========*/
    --body-font: 'Poppins', sans-serif;
    --biggest-font-size: 2.25rem; /* 36px */
    --h1-font-size: 1.5rem; /* 24px */
    --h2-font-size: 1.25rem; /* 20px */
    --h3-font-size: 1rem; /* 16px */
    --normal-font-size: .938rem; /* 15.008px */
    --small-font-size: .813rem; /* 13.008px */
    --smaller-font-size: .75rem; /* 12px */

    /*========== Font weight ==========*/
    --font-normal: 400;
    --font-medium: 500;
    --font-semi-bold: 600;

    /*========== Z-index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;

}

/* Responsive Typography */
@media screen and (min-width: 1024px) {
    :root {
        --biggest-font-size: 4rem; /* 64px */
        --h1-font-size: 2.25rem; /* 36px */
        --h2-font-size: 1.5rem; /* 24px */
        --h3-font-size: 1.25rem; /* 20px */
        --normal-font-size: 1rem; /* 16px */
        --small-font-size: .875rem; /* 14px */
        --smaller-font-size: .813rem; /* 13.008px */
    }    
}

/*========== BASE ==========*/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--body-color);
    color: var(--text-color);
    transition: .3s;
}

h1, h2, h3 {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
}

ul {
    list-style: none;
    margin: 0;
}

a {
    text-decoration: none;
    color: var(--text-color);
}

a:hover {
    color: inherit;
}

img {
    max-width: 100%;
    height: auto;
}

input,
button {
    font-family: var(--body-font);
    outline: none;
    border: none;
}

/*========== REUSABLE CSS CLASSES ==========*/
.container {
    /* max-width: 1024px; */
    max-width: 1320px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.grid {
    display: grid;
}

.section {
    /* padding: 4.5rem 0 2rem; */
    padding: 1rem 0 0;
}

.section__title {
    font-size: var(--h2-font-size);
    margin-bottom: 1rem;
}

.section__title span {
    color: var(--second-color);
}

.section__subtitle {
    display: block;
    font-size: var(--small-font-size);
    color: var(--second-color);
}

.section__small-title {
    font-size: var(--smaller-font-size);
}

.main {
    margin-top: 61px;
}

.compButton {
    background-color: var(--second-color);
    color: var(--white);
    padding: 1rem 2rem;
    border-radius: 3px;
    cursor: pointer;
}

/*========== INPUT SPINNER ==========*/
.spinner__group {
    /* background-color: var(--container-color); */
    border: 1px solid var(--first-color);
    color: var(--first-color);
    border-radius: 100px;
    width: 6.25rem;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 8px hsla(210, 100%, 14%, .1);
}

.spinner__minus,
.spinner__plus {
    cursor: pointer;
} 

.spinner__count {
    font-weight: 500;
}

/*========== BUTTON ==========*/
.button {
    display: inline-block;
    background-color: var(--first-color);
    color: #fff;
    padding: 14px 28px;
    border-radius: .5rem;
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    box-shadow: 0 4px 8px hsla(210, 100%, 14%, .25);
    transition: .3s;
    cursor: pointer;
}

.button:hover {
    box-shadow: 0 4px 12px hsla(210, 100%, 14%, .25);
}

/*==================== HEADER & NAV ====================*/
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: var(--z-fixed);
    background-color: var(--first-color);
    box-shadow: 0 1px 4px hsla(210, 100%, 14%, .1);
    transition: .4s;
}

.nav {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo img {
    width: 220px;
    margin-top: 6px;
}

.go__search {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 1.1rem;
}

.nav__search {
    background-color: #fff;
    padding: .35rem .35rem .35rem .75rem;
    display: none;
    align-items: center;
    border-radius: .5rem;
    width: 500px;
    margin: 0 auto;
}

.nav__search .button {
    padding: 5px 25px;
    border-radius: .35rem;
}

.nav__search svg {
    font-size: 1.25rem;
    color: var(--first-color);
}

.nav__search-input {
    width: 90%;
    background-color: #fff;
    color: var(--first-color);
    margin: 0 .5rem;
}

.nav__search-input::placeholder {
    color: var(--text-color);
}

@media screen and (max-width: 1023px) {
    .nav__menu {
        position: fixed;
        bottom: 2rem;
        background-color: var(--container-color);
        box-shadow: 0 8px 24px hsla(210, 100%, 14%, .15);
        width: 90%;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 1.30rem 3rem;
        border-radius: 1.25rem;
        transition: .4s;
    }

    .nav__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav__link {
        color: var(--first-color);
        display: flex;
        padding: .5rem;
        border-radius: 50%;
        position: relative;
        transition: .4s;
    }

    .nav__link:hover {
        color: var(--first-color);
    }

    .nav__link svg {
        font-size: 1.5rem;
    }

    .nav__link span {
        display: none;
    }

    .nav__link .count {
        display: inline-flex;
        position: absolute;
        top: 0;
        right: 2px;
        background-color: var(--second-color);
        width: 17px;
        height: 17px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: var(--smaller-font-size);
        color: #fff;
    }
}

/* Active link */
.active-link {
    background-color: var(--first-color);
    color: #fff;
    box-shadow: 0 4px 8px hsla(210, 100%, 10%, .25);
}

.active-link:hover {
    color: #fff;
}

/*==================== MAIN ====================*/
.main {
    margin-top: var(--header-height);
}

/*==================== FOOTER ====================*/
.footer__container {
    row-gap: 2.5rem;
}

.footer__content,
.footer__items {
    display: grid;
}

.footer__content {
    grid-template-columns: repeat(1, max-content);
    gap: 2.5rem 4rem;
}

.footer__title {
    font-size: var(--h3-font-size);
    margin-bottom: .5rem;
}

.footer__description {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    color: var(--text-color);
    margin-top: -5px;
    text-overflow: ellipsis;
}

.footer__search {
    padding: .75rem .25rem;
    display: flex;
    align-items: center;
    border: 3px solid var(--first-color);
    position: relative;
    margin-top: 1rem;
    width: 280px;
}

.footer__search .icon {
    font-size: 1rem;
    color: #fff;
    background-color: var(--first-color);
    width: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -1px;
    cursor: pointer;
}

.footer__search .icon svg {
    position: absolute;
    top: 40%;
    left: 30%;
}

.footer__search-input {
    width: 90%;
    background-color: transparent;
    color: var(--first-color);
    margin: 0 .5rem;
}

.footer__search-input::placeholder {
    color: var(--text-color);
}

.footer__items {
    row-gap: .5rem;
}

.footer__hr {
    margin: .75rem 0;
    border: 1px solid var(--text-color);
}

.footer__item-link {
    color: var(--text-color);
    transition: .3s;
}

.footer__item-link:hover {
    color: var(--title-color);
}

.footer__social-media {
    margin-top: 1rem;
}

.footer__social {
    display: flex;
    column-gap: 1rem;
}

.footer__social-link {
    font-size: 1.25rem;
    color: var(--text-color);
    transition: .3s;
}

.footer__social-link:hover {
    color: var(--first-color);
}

.footer__info,
.footer__privacy {
    display: flex;
}

.footer__info {
    padding-bottom: 7rem;
    margin-top: 5.5rem;
    flex-direction: column;
    text-align: center;
    row-gap: .5rem;
}

.footer__copy,
.footer__privacy a {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    color: var(--text-color);
}

.footer__privacy {
    justify-content: center;
    column-gap: 1.25rem;
}

/*==================== SCROLL BAR ====================*/
::-webkit-scrollbar {
    width: .6rem;
    border-radius: .5rem;
    background-color: hsl(0, 0%, 88%);
}

::-webkit-scrollbar-thumb {
    background-color: hsl(228, 8%, 64%);
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover {
    background-color: hsl(228, 8%, 54%);
}


/*==================== SCROLL UP ====================*/
.scrollup {
    position: fixed;
    right: 1rem;
    bottom: -30%;
    background-color: var(--container-color);
    box-shadow: 0 8px 12px hsla(210, 100%, 14%, .1);
    display: inline-block;
    width: 60px;
    height: 60px;
    padding: .25rem;
    border-radius: .25rem;
    color: var(--text-color);
    font-size: 1.25rem;
    z-index: var(--z-tooltip);
    transition: .3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.scrollup:hover {
    transform: translateY(-.25rem);
    color: var(--first-color);
}

/* Show Scroll Up */
.show-scroll {
    bottom: 8rem;
}

/*==================== BREAKPOINTS ====================*/
/* For small devices */

@media screen and (max-width: 750px) {
    .container {
        margin-left: 0;
        margin-right: 0;
    }

    .header .nav {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    footer.footer.section {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media screen and (max-width: 350px) {
    .nav__menu {
        padding: 1.3rem 1.5rem;
    }

    .section__small-title {
        font-size: 9px;
    }
}

/* For medium devices */
@media screen and (min-width: 576px) {
    .footer__content {
        grid-template-columns: repeat(2, max-content);
        gap: 2.5rem 2rem;
        justify-content: space-between;
    }
}

@media screen and (min-width: 767px) {
    .nav__menu {
        width: 500px;
    }
}

@media screen and (min-width: 850px) {
    .footer__content {
        grid-template-columns: repeat(4, max-content);
        gap: 2.5rem 1rem;
        justify-content: space-between;
    }
}

/* For large devices */
@media screen and (min-width: 1024px) {
    .section__title {
        font-size: 2.25rem;
    }

    .section__subtitle {
        font-size: var(--normal-font-size);
    }

    .go__search,
    .nav__item__search {
        display: none;
    }

    .nav__search {
        display: flex;
        width: 350px;
    }

    .nav__menu {
        width: initial;
        margin-left: auto;
    }

    .nav__list {
        display: flex;
        column-gap: 1rem;
        margin-top: 12px;
    }

    .nav__link {
        color: #fff;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        position: relative;
    }

    .nav__link span {
        font-size: var(--small-font-size);
        margin-left: 5px;
    }

    .nav__link .count {
        position: absolute;
        top: -8px;
        right: -8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: var(--second-color);
        min-width: 20px;
        height: 20px;
        border-radius: 10px;
        font-size: var(--smaller-font-size);
        color: #fff;
    }

    .footer__info {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 2rem;
    }

    .show-scroll {
        bottom: 3rem;
        right: 3rem;
    }
}

@media screen and (min-width: 1200px) {
    .nav__search {
        width: 500px;
    }
}

@media screen and (min-width: 1440px) {
    .container {
        margin-left: auto;
        margin-right: auto;
    }

    .nav__search {
        width: 600px;
    }

    .footer__info {
        width: 100%;
    }
}

@media screen and (min-width: 1650px) {
    body {
        zoom: 1.2;
    }
}

/* For 2k & 4k resolutions */
@media screen and (min-width: 2048px) {
    body {
        zoom: 1.5;
    }
}

@media screen and (min-width: 3840px) {
    body {
        zoom: 2;
    }
}